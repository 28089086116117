.container{
    /* border: 1px solid black; */
    width: 100% !important;
    display: flex !important;
    /* flex-direction: row !important; */
    justify-content: space-between !important; 
}

.ant-divider{
    height: '2px' !important;
}

#blink{
   animation: blinker 1s step-start infinite !important;
}

/* .btn::before{
  content: "Announcements" !important;
  animation: blinker 1s step-start infinite !important;
} */

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.btn{
 border: 1px solid #258e25 !important;
 background-color: #258e25!important;
 border-radius: 10px !important;
 height: 55px !important;
 text-transform: none !important;
 /* line-height: 30px !important; */
 /* text-align: center !important; */
 /* box-sizing: border-box !important; */
 /* display: flex !important;
 align-items: center !important; */
 margin: auto !important;
 color: white !important;
 font-size: 1.2em !important;
 font-weight: bold !important;
}