.header {
    position: fixed;
    width: 100%;
    height: 40px;
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border: dashed 1px #4F58D2;
    color: #ABB0B7;
    background-color: white;
    height: 40px;
    width: 100%;
  }
  
  .header__left {
    display: flex;
    align-items: center;
    flex-basis: 30%;
    justify-content: center;
    font-weight: 700;
    color: white;
    height: 40px;
    background-color: #4F58D2;
    width: 100%;
    
  }

  .mobile-none{
    display: block;
  }

  .header__right {
    display: flex;
    align-items: center;
    flex-basis: 70%;
    padding: 0px;
    padding-left: 5px;
  }
  
  .header__title {
    font-size: 2em;
    margin: 0;
  }
  
  .header__email,
  .header__phone {
    display: flex;
    align-items: center;
    color: #ABB0B7;
    text-decoration: none;
    font-size: 1.1em;
    margin-right: 20px;
  }
  
  .header__icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
   
  }
  
  .header__social-link {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #ABB0B7;
    font-size: 1.1em;
  }

  .header__button {
    background-color: blue;
    color: white;
    border-radius: 5px; /* add a little bit of border radius */
    font-size: 1em;
    border: none;
    margin-right: 4px;
  }
  


  @media only screen and (max-width: 768px) {
    .header__left {
      font-size: 11px;
      padding-right: 4px;
      padding-left: 4px;
      
    }
    .mobile-none{
      display: none;
    }
  }
  
  