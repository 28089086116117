body {
  margin: 0;
  padding: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  /* font-family: 'Merriweather', serif; */
  /* font-family: 'Playfair Display', serif; */
font-family: 'Open Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: 'rohini';
  src: local('rohini'), url('./fonts/Asomiya_Rohini.ttf') format('truetype');
}