.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  from {
    color: green;
  }

  to {
    color: red;
  }

  50% {
    opacity: 0;
  }
}


a:hover {
  text-decoration: none !important;
}

.navStyle-new {
  width: 100%;
}


.faculty-image {
  width: 100%;
  height: 30vh;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}


.verticle-card-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 600px) {
  .navStyle-new {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    width: 100%;
    background-color: #b94735 !important;
    background-color: hsl(60, 4%, 5%) !important;
  }

  .navStyle-new-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #001529 !important;

  }

  .m-display {
    display: none !important;
  }

  .m-none {
    display: unset !important;
  }

}




.departmental-header-text {
  font-weight: 500 !important;
}


.main-header-class {
  min-height: 60px;

}


.iso-text{
  line-height: 1.22;
  font-size: 16px;
  font-weight: 700;
  color: #4F57D1;
}

.ant-menu-vertical{
  background-color: #4F58D2 !important;
}

.ant-menu-title-content{
  /* color: white !important */
}


@media only screen and (max-width: 600px) {
  .faculty-image {
    width: 80%;
    height: 80%;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }

  .ant-layout-header {
    height: 200px !important;
    padding: 0 0px !important;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #001529;
  }

  .departmental-header-text {
    font-size: 14px !important;
  }
  

  .iso-text{
    font-size: 10px;
    padding-left: 10px;
  }

  /* .m-display{
    display: unset !important;
  }
  */

  .m-none {
    display: none !important;
  }

}



.new-principal-div {
  border: solid 3px grey;
  padding: 20px;
  background-color: #fff6ca;
}

.bg-primary {
  background-color: #001529 !important;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

/* new class created by mrin*/

.hr {
  height: 2px;
  background: white;
  width: 900px;
}

.round-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 3px solid white;
}



@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Dosis&family=Poppins:wght@700&family=Roboto:wght@400;500&display=swap');
/* 
font-family: 'Cabin', sans-serif;
font-family: 'Dosis', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */

.fontFix {
  /* font-family:  'Poppins', sans-serif; */
}

.round {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EC8444;
  color: #251D25;
  border-radius: 50%;
  padding: 1em;
  margin: 3em;
  transition-duration: 5sec;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 100ms;
  -o-transition-timing-function: ease-in-out;
  cursor: pointer;
}

.round:hover {
  background-color: #EBDB86;
  transform: scale(0.9);
}

.news {
  background: #EBDB86;
}

.news-title {
  color: #B94735;
  cursor: pointer;
  font-weight: 400 !important;
}

.news-title:hover {
  color: #251D25
}

.vdamaz {
  transform: scale(0.95);
  animation: ease-in-out 2s;
}

.vdamaz:hover {
  transform: scale(1);
}

.navItemPandu {
  color: white !important;
  border-bottom: none !important;
  padding: 5px 10px !important;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px !important;
  font-family: "Roboto";
}


.navItemPandu1 {
  color: white !important;

  font-size: 14px !important;
  font-weight: 600;
  margin: 0px !important;
  height: 30px !important;
  font-weight: 500 !important;
  font-family: "Roboto";
}

.navItemPandu2 {
  padding-bottom: 20px;
}

.navItemPandu:hover {
  /* background: #F5EB3D !important;
  background: #000 !important; */
  color: lightgreen;
}

.footer {
  background: #181818 !important;
  color: #EBDB86;
  width: 100%;
}

.foot1h {
  color: #EBDB86;
}

.add p {
  font-size: medium;
  margin: 0;
  padding: 0;
}

.MuiListItem-button:hover {
  background-color: #EBdB86 !important;
}

.logo-img {
  width: 100%;
  text-align: center;
}

.logo-img-1 {
  width: 33% !important;
  margin-left: 10px;
  text-align: left !important;
}

.logo-img-11{
  width: 100px !important;
  margin-left: 10px;
  text-align: left !important;
}

.logo-img-container {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.div-container-icon-shortcut {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }
}


@media only screen and (max-width: 768px) {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: 0 10px !important;
  }

  .nav-bar-div-text-h {
    color: #251d25 !important;
    font-size: x-large !important;
    text-align: center;
  }

  .ant-menu-title-content{
    color: #000080 !important;
  }


  .ant-menu-submenu-popup>.ant-menu {
    background-color: #F5EB3D !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F5EB3D !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }


  .logo-img-container {

    flex-direction: column;

  }
}

.feestable {
  margin: 1em 0;
}

.feestable td {
  font-weight: 400;
  padding: 5px 30px;
}

.noticecard {
  background: transparent;
  background-color: #f1f1f1;
  padding: 5px;
}

.new-card-head {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.subhead {
  /* border-bottom: 3px solid black; */
  font-weight: 700;
  font-size: 20px;
}

.subhead:hover {
  border-bottom: 2px solid black;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 80% !important;
  }

  .gapround {
    padding: 0 50px;
  }
}

p {
  line-height: 1.875em !important;
}



.table>thead>tr>td {
  background-color: #4F58D2;
  color: white;
  /* border-color: white; */
  font-weight: bold;
  padding: 3px;
  text-align: center;
  font-size: 14px;
}

.table>tbody>tr>td {

  padding: 3px;
  font-size: 12px;
  font-weight: 500;
}

p {
  margin: 0 !important;
}

.table-new-color>thead>tr>td {
  background-color: #4F58D2;
  border-color: white;
}

.table-new-color>tbody>tr>td {
  border-color: #566573;
}


.desk-menu {}

.mobile-menu-div {
  display: none;
}

.paper-home-page-div {
  background-color: #2054CD;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper-home-page-about {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.center-announcements {
  width: 80%;
  align-self: center;
}

.paper-adm-homepage {
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.paper-adm-homepage-div {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: -1px 1px 15px 2px #e6e6e6;
  margin-right: 10px;
}

.paper-adm-homepage-link {
  font-weight: bold;
  font-size: 1.2em;
  color: #000080;
  text-decoration: none;
}


.paper-prince-homepage {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.paper-prince-div-1 {
  width: 30%;

}

.paper-prince-div-2 {
  width: 70%;
  display: flex;
  justify-content: center;

}

.paper-homepage-motto {
  display: flex;
  align-items: center;
}

.paper-homepage-motto-div {
  flex-basis: 25%;
}

.paper-homepage-motto-div-1 {
  flex-basis: 75%;
}



.paper-homepage-awards-div {
  display: flex;
  min-height: 200px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}


.principal-image-home-div {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 1000;
}

.principal-image-home-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 0px;
}


/* warpping text and image */

.img-wrap-image {
  width: 200px;
  height: auto;
  float: left;
  margin-right: 20px;
}


.p-warp-image {
  font-size: 16px;
  line-height: 1.6;

}

.carousel-main-image {
  width: 100%;
  object-fit: cover !important;
  height: 650px;
}

@media only screen and (max-width: 768px) {
  .desk-menu {
    display: none;
  }

  .mobile-menu-div {
    display: block;
    position: absolute;
    right: 0px;
    top: 50px;
    z-index: 100;
  }

  .logo-img {
    width: 100% !important;
    text-align: left !important;
  }

  .logo-img-1 {
    width: 50px !important;
    text-align: left !important;
  }

  .paper-home-page-about {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .paper-home-page-div {
    padding: 10px;
  }

  .paper-adm-homepage {
    width: 100%;
    min-height: 300px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2px;
    column-gap: 2px;
  }

  .paper-adm-homepage-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;

  }

  .paper-adm-homepage-link {
    font-weight: bold;
    font-size: 1.2em;
    color: #000080;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }

  .paper-prince-homepage {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .paper-prince-div-1 {
    width: 100%;

  }

  .paper-prince-div-2 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

  }


  .paper-homepage-motto {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .paper-homepage-motto-div {
    flex-basis: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .paper-homepage-motto-div-1 {
    flex-basis: 100%;
    width: 100%
  }

  .paper-homepage-awards-div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .carousel-main-image {
    width: 100% !important;
    object-fit: cover !important;
  }
}



.new-alert-text {
  animation: colorchange 0.5s infinite;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
}

@keyframes colorchange {
  0% { color: #EBAE38; }
  50% { color: red; }
  100% { color: #EBAE38; }
}


.zoom-style-text {
  animation: zoomstyle 0.5s infinite;
  transform: scale(1.4) !important;
  line-height: normal;
}

@keyframes zoomstyle {
  0% { transform:scale(1); }
  50% { transform:scale(1.2); }
  100% { transform:scale(1); }
  
}


.img-spin{
  -webkit-animation-name: spinstyle;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spinstyle;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spinstyle;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spinstyle;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spinstyle {
  from { -ms-transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spinstyle {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spinstyle {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spinstyle {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.prose  li {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.prose  p {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.prose h4 {
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}