.enquiryBtn {
    position: fixed;
    left: 0;
    top: 50%;
    writing-mode: vertical-lr !important;
    background-color: orangered;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
}

/* .formContainer{
    width:'25%';
    height: '100%';
    position:'fixed'; 
    background-color:'white'; 
    z-index: '1';
    top:0
} */

.closeBtn{
    position: 'absolute';
    top: 0;
    right: 0
}